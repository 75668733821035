import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  catchError,
  finalize,
  map,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { Router } from '@angular/router';
import { getErrorMessage } from '../helpers/error-map';
import { BaseEndpointService } from '../../../shared/interfaces/endpoint';
import { AuthResponseModel } from '../models/authResponseModel';
import { environment } from '../../../../environments/environment';

export const status = {
  successful: 'SUCCESSFUL',
  failed: 'FAILED',
  notStarted: 'NOT STARTED',
  refreshing: 'IN PROGRESS',
} as const;

type StatusValues = (typeof status)[keyof typeof status];

interface RefreshResult {
  isRefreshing: boolean;
  status: StatusValues;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseEndpointService {
  private readonly router = inject(Router);
  public readonly isLoggedIn$ = new BehaviorSubject(false);

  public readonly isRefreshingToken$ = new BehaviorSubject<RefreshResult>({
    isRefreshing: false,
    status: 'NOT STARTED',
  });

  constructor() {
    super({ route: 'auth' });
  }

  public get isLoggedIn() {
    return this.isLoggedIn$.value;
  }

  public set isLoggedIn(value: boolean) {
    this.isLoggedIn$.next(value);
  }

  isAuthenticated(): Observable<boolean> {
    return this.http
      .get<{ isAuthenticated: boolean }>(this.endpoint + '/verify', {
        withCredentials: true,
      })
      .pipe(
        map((response) => response.isAuthenticated),
        tap((isAuthenticated) => this.isLoggedIn$.next(isAuthenticated))
      );
  }

  login(model: { email: string; password: string }) {
    return this.http
      .post<AuthResponseModel>(this.endpoint + '/login', {
        ...model,
      })
      .pipe(
        tap((result) => {
          this.isLoggedIn$.next(true);
        }),
        catchError((error) => this.handleError(error))
      );
  }

  refreshToken() {
    this.isRefreshingToken$.next({ isRefreshing: true, status: 'IN PROGRESS' });

    return this.http
      .post<AuthResponseModel>(this.endpoint + `/token/refresh`, {})
      .pipe(
        tap((result) => {
          this.isRefreshingToken$.next({
            isRefreshing: false,
            status: 'SUCCESSFUL',
          });
          this.isLoggedIn$.next(true);
        }),
        catchError((error) => {
          this.isRefreshingToken$.next({
            isRefreshing: false,
            status: 'FAILED',
          });
          return this.logout();
        }),
        finalize(() => {
          this.isRefreshingToken$.next({
            isRefreshing: false,
            status: 'NOT STARTED',
          });
        })
      );
  }

  logout() {
    this.isLoggedIn$.next(false);
    this.router.navigateByUrl('/login');

    return this.http.get(this.endpoint + '/logout');
  }

  handleError(errorRes: any) {
    let errorMessage = 'An unknown error occurred!';
    console.log(errorRes);
    const errorDetail =
      errorRes.error != null
        ? errorRes.error.replaceAll(' ', '').split(':')[1]
        : null;

    if (errorDetail) {
      errorMessage = getErrorMessage(errorDetail);
    }

    return throwError(() => new Error(errorMessage));
  }
}
