import { Injectable, inject } from '@angular/core';
import { BaseEndpointService } from '../../../shared/interfaces/endpoint';
import { Observable, filter, map, switchMap, take, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../auth/data-access/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastService } from '../../../shared/services/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseEndpointService {
  private readonly authService: AuthService = inject(AuthService);
  private readonly permissionsService: NgxPermissionsService = inject(NgxPermissionsService);
  private readonly toastService:ToastService = inject(ToastService);

  constructor() {
    super({ route: 'account/' });

    this.authService.isLoggedIn$
      .pipe(
        filter((value) => value == true),
        switchMap(() => this.roles())
      )
      .subscribe((data: string[]) => {
        this.permissionsService.loadPermissions(data)
      });
  }

  isAdmin(): Observable<boolean> {
    return this.http
      .get<{ isAdmin: boolean }>(this.endpoint + 'is-admin', {
        withCredentials: true,
      })
      .pipe(map((response) => response.isAdmin));
  }

  roles(): Observable<any> {
    return this.http.get(this.endpoint + 'roles', {
      withCredentials: true,
    });
  }

  me(){
    return this.http.get(this.endpoint + 'me', {
      withCredentials:true
    })
  }

  resetPassword(model)
  {
    return this.http.post(`${this.endpoint}/reset-password`,{...model}).pipe(tap(() => this.toastService.showSuccess("Password Reset Succesfully!")))
  }
}
